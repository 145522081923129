import React, { useState, useEffect } from "react"
import $ from "jquery"
import axios from "axios"
import { navigate } from "gatsby"

import t from "../locale"

const ContactForm = ({ lang }) => {
  const [send, setSend] = useState(false)
  const [success, setSuccess] = useState(false)
  const [name, setName] = useState("")
  const [company, setCompany] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [help, setHelp] = useState("")
  const [source, setSource] = useState("")
  const [legalFirst, setLegalFirst] = useState(false)
  const [legalSecond, setLegalSecond] = useState(false)

  const resetForm = () => {
    setSend(false)
    setName("")
    setCompany("")
    setEmail("")
    setPhone("")
    setHelp("")
    setSource("")
    setLegalFirst(false)
    setLegalSecond(false)
  }

  useEffect(() => {
    $("form input, form textarea").focusin(function () {
      $(this).parent().addClass("fill")
    })
    $("form input, form textarea").focusout(function () {
      var inputVal = $(this).val().length
      if (inputVal > 0) {
        $(this).parent().addClass("fill")
      } else {
        $(this).parent().removeClass("fill")
      }
    })
  })

  const formSubmit = e => {
    e.preventDefault()
    setSend(true)

    let formData = new FormData()

    formData.set("fullName", name)
    formData.set("company", company)
    formData.set("email", email)
    formData.set("phone", phone)
    formData.set("help", help)
    formData.set("source", source)
    formData.set("legalFirst", legalFirst)
    formData.set("legalSecond", legalSecond)

    axios({
      method: "post",
      url:
        "https://cms.unitycentre.pl/wp-json/contact-form-7/v1/contact-forms/14/feedback/",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(res => {
        console.log("Submit success")
        resetForm()
        setSuccess(true)
        lang === "en"
          ? navigate("/contact-confirmation/")
          : navigate("/pl/kontakt-potwierdzenie/")
      })
      .catch(() => {
        console.log("Submit error")
      })
  }

  return (
    <form className="form" onSubmit={formSubmit}>
      <div className="row">
        <div className="col-md-5">
          <div className="input-wrapper">
            <label htmlFor="name">{t("Name", lang)}*</label>
            <input
              type="text"
              id="name"
              name="name"
              value={name}
              onChange={e => setName(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="col-md-5 offset-md-1">
          <div className="input-wrapper">
            <label htmlFor="company">{t("Company", lang)}</label>
            <input
              type="text"
              id="company"
              name="company"
              value={company}
              onChange={e => setCompany(e.target.value)}
            />
          </div>
        </div>
        <div className="col-md-5">
          <div className="input-wrapper">
            <label htmlFor="email">{t("E-mail", lang)}*</label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="col-md-5 offset-md-1">
          <div className="input-wrapper">
            <label htmlFor="phone">{t("Phone", lang)}*</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={phone}
              onChange={e => setPhone(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="col-md-5">
          <div className="input-wrapper">
            <label htmlFor="help">{t("How can we help you?", lang)}</label>
            <input
              type="text"
              id="help"
              name="help"
              value={help}
              onChange={e => setHelp(e.target.value)}
            />
          </div>
        </div>
        <div className="col-md-5 offset-md-1">
          <div className="input-wrapper">
            <label htmlFor="source">
              {t("How did you hear about us?", lang)}
            </label>
            <input
              type="text"
              id="source"
              name="source"
              value={source}
              onChange={e => setSource(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="form__actions">
        <div>
          <button
            type="submit"
            className="btn-custom-back btn-custom-back--white"
            disabled={!legalFirst}
          >
            {send === true ? t("Sending..", lang) : t("Send message", lang)}
          </button>
          {success && <span>{t("Message sent!", lang)}</span>}
        </div>
        <p>
          <label className="checkbox-wrapper">
            <input
              type="checkbox"
              checked={legalFirst}
              onChange={e => setLegalFirst(!legalFirst)}
              required
            />
            <span>
              *
              {t(
                "CONSENT TO RECEIVE A DEDICATED TRADE OFFER - WE NEED IT IN ORDER TO CONTACT YOU. I consent to the processing of my personal data by TREIMORFA PROJECT SP. Z O.O. in order to receive the information about the offers of the Company's commercial partners, included in the Company's offer.",
                lang
              )}
            </span>
          </label>
          <label className="checkbox-wrapper">
            <input
              type="checkbox"
              checked={legalSecond}
              onChange={e => setLegalSecond(!legalSecond)}
            />
            <span>
              {t(
                "CONSENT TO RECEIVE PHONE CALLS - IT IS ALWAYS FASTER TO DISCUSS THE SUBJECT DIRECTLY WITH EACH OTHER. I agree to receive information and offers from TREIMORFA PROJECT SP. Z O.O. via direct marketing, by using telecommunication devices of which I am a user of.",
                lang
              )}
            </span>
          </label>
        </p>
      </div>
    </form>
  )
}

export default ContactForm
